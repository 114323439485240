var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--jobs" },
    [
      _c("the-layout-header", {
        staticClass: "pt-16",
        attrs: {
          title: _vm.$t("careers.header"),
          "subtitle-line-one": _vm.$t("careers.subtitle"),
        },
      }),
      _c("v-container", { staticClass: "my-15" }, [
        _c("img", {
          staticClass: "mb-8 mt-0 mt-md-10",
          staticStyle: { width: "100%" },
          attrs: {
            src: require("@/assets/images/team/mew-collage-small.jpg"),
            alt: "Members",
          },
        }),
        _c("div", { staticClass: "mb-15" }, [
          _c("div", { staticClass: "pa-3 mew-heading-1 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("careers.join-mew.title")) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "mx-auto text-center",
              staticStyle: { "max-width": "600px" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("careers.join-mew.description")) + " ")]
          ),
        ]),
        _c("div", { staticClass: "mb-12" }, [
          _c("div", { staticClass: "pa-3 mew-heading-1 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("careers.contact-us.title")) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "mx-auto text-center",
              staticStyle: { "max-width": "600px" },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("careers.contact-us.email-us")) + " "),
              _c(
                "a",
                {
                  attrs: {
                    href: "mailto:careers@myetherwallet.com",
                    target: "_blank",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("careers.contact-us.email")) + " ")]
              ),
              _vm._v(
                " " + _vm._s(_vm.$t("careers.contact-us.email-us-end")) + " "
              ),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "mx-auto pt-1",
            staticStyle: { "max-width": "600px" },
          },
          _vm._l(_vm.jobs, function (value, name) {
            return _c("div", { key: name, staticClass: "mt-15" }, [
              value.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "pa-3 mew-heading-2" }, [
                        _vm._v(_vm._s(name)),
                      ]),
                      _c("v-divider", { staticClass: "mb-4" }),
                      _vm._l(value, function (job, idx) {
                        return _c(
                          "a",
                          {
                            key: job.title + idx,
                            staticClass: "pa-3",
                            attrs: { href: job.link, target: "_blank" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mew-heading-3 orangePrimary--text",
                              },
                              [_vm._v(" " + _vm._s(job.title) + " ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "textLight--text" },
                              _vm._l(job.other, function (other, indx) {
                                return _c(
                                  "span",
                                  { key: other + indx },
                                  [
                                    _vm._v(" " + _vm._s(other) + " "),
                                    indx !== job.other.length - 1
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "textLight" } },
                                          [_vm._v("mdi-circle-small")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }