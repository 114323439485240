var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", {
              directives: [
                {
                  name: "lottie",
                  rawName: "v-lottie",
                  value: "checkmark",
                  expression: "'checkmark'",
                },
              ],
              staticClass: "lottie py-7",
              staticStyle: { height: "200px" },
            }),
          ]),
          _c("v-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
            _c("p", { staticClass: "mew-heading-2" }, [
              _vm._v("Registration Completed"),
            ]),
            _c("p", { staticClass: "mew-heading-4" }, [
              _vm._v(
                " Please navigate to the 'Manage Domains' tab to see your newly registered name "
              ),
            ]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", "align-self": "center" },
            },
            [
              _c("mew-button", {
                attrs: { "btn-size": "xlarge", title: "Close" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.close.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }