import { render, staticRenderFns } from "./NftManagerDetails.vue?vue&type=template&id=017a144c&scoped=true"
import script from "./NftManagerDetails.vue?vue&type=script&lang=js"
export * from "./NftManagerDetails.vue?vue&type=script&lang=js"
import style0 from "./NftManagerDetails.vue?vue&type=style&index=0&id=017a144c&lang=scss&scoped=true"
import style1 from "./NftManagerDetails.vue?vue&type=style&index=1&id=017a144c&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "017a144c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('017a144c')) {
      api.createRecord('017a144c', component.options)
    } else {
      api.reload('017a144c', component.options)
    }
    module.hot.accept("./NftManagerDetails.vue?vue&type=template&id=017a144c&scoped=true", function () {
      api.rerender('017a144c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/nft-manager/components/NftManagerDetails.vue"
export default component.exports