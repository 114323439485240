var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mew-component--module-side-apr pa-5 border-radius--10px box-border",
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-top justify-space-between mb-1" },
        [
          _vm._m(0),
          _c("div", { staticClass: "text-right" }, [
            _c("div", { staticClass: "font-weight-bold mew-heading-3 mb-1" }, [
              _vm._v(" " + _vm._s(_vm.formattedBalance) + " "),
            ]),
            _vm.ethvmSupport
              ? _c("div", { staticClass: "textLight--text" }, [
                  _vm._v(" " + _vm._s(_vm.sethBalanceFiat) + " "),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm.isEthNetwork
        ? _c(
            "div",
            _vm._l(_vm.stakewiseTxs.ETH, function (tx) {
              return _c(
                "div",
                {
                  key: tx.hash,
                  staticClass: "d-flex justify-space-between flex-wrap mt-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "py-1" },
                    [
                      _c("v-progress-circular", {
                        staticClass: "mr-1",
                        attrs: {
                          indeterminate: "",
                          color: "greenPrimary",
                          width: 2,
                          size: 20,
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(tx.amount) +
                          " " +
                          _vm._s(_vm.currencyName) +
                          " Pending "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "greenPrimary--text font-weight-medium d-flex align-center cursor--pointer py-1",
                      on: {
                        click: function ($event) {
                          return _vm.checkHash(tx.hash)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " View on " +
                          _vm._s(_vm.ethvmSupport ? "EthVM" : "EtherScan") +
                          " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-1",
                          attrs: { color: "greenPrimary", small: "" },
                        },
                        [_vm._v("mdi-open-in-new")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            _vm._l(_vm.stakewiseTxs.GOERLI, function (tx) {
              return _c(
                "div",
                {
                  key: tx.hash,
                  staticClass: "d-flex justify-space-between mt-4",
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-progress-circular", {
                        staticClass: "mr-1",
                        attrs: {
                          indeterminate: "",
                          color: "greenPrimary",
                          width: 2,
                          size: 20,
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(tx.amount) +
                          " " +
                          _vm._s(_vm.currencyName) +
                          " Pending "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "greenPrimary--text font-weight-medium d-flex align-center cursor--pointer",
                      on: {
                        click: function ($event) {
                          return _vm.checkHash(tx.hash)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " View on " +
                          _vm._s(_vm.ethvmSupport ? "EthVM" : "EtherScan") +
                          " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-1",
                          attrs: { color: "greenPrimary", small: "" },
                        },
                        [_vm._v("mdi-open-in-new")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
      _vm.hasStaked
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-space-between mt-4 flex-wrap-reverse",
            },
            [
              _vm.isEthNetwork
                ? _c("mew-button", {
                    staticClass: "py-1",
                    attrs: {
                      title: "Redeem to " + _vm.currencyName,
                      "btn-style": "transparent",
                      "btn-size": "small",
                      disabled: _vm.enoughToCoverRedeem,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.executeSwap.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.enoughToCoverRedeem
        ? _c("div", { staticClass: "mt-4 greyPrimary--text" }, [
            _vm._v(" You do not have enough ETH to cover transaction fee. "),
            _c(
              "a",
              {
                attrs: { rel: "noopener noreferrer" },
                on: {
                  click: function () {
                    _vm.openBuySell("StakewiseStaking")
                  },
                },
              },
              [_vm._v(" Buy more ETH")]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-center mt-n4" }, [
      _c("div", { staticClass: "stake-icon mr-2" }, [
        _c("img", {
          attrs: {
            src: require("@/dapps/stakewise/assets/icon-stakewise-green.svg"),
            alt: "Stakewise",
          },
        }),
      ]),
      _c("div", { staticClass: "textLight--text" }, [
        _c("span", { staticClass: "textMedium--text" }, [_vm._v("Staking")]),
        _vm._v(" - sETH2 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }