var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "white-sheet",
    {
      staticClass: "mx-auto py-4 px-6",
      staticStyle: { "max-width": "650px", width: "100%" },
    },
    [
      _c("mew-stepper", {
        staticClass: "mx-md-0",
        attrs: { items: _vm.items, "on-step": _vm.step },
        scopedSlots: _vm._u(
          [
            _vm.step === 1
              ? {
                  key: "stepperContent1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "subtitle-1 font-weight-bold grey--text",
                        },
                        [_vm._v("STEP 1.")]
                      ),
                      _c(
                        "div",
                        { staticClass: "headline font-weight-bold mb-5" },
                        [_vm._v("Create password")]
                      ),
                      _c("mew-input", {
                        staticClass:
                          "flex-grow-1 mb-2 CreateWalletKeystorePasswordInput",
                        attrs: {
                          hint:
                            _vm.password && _vm.password.length < 8
                              ? "Password must be 8 or more characters"
                              : "",
                          label: "Password",
                          placeholder: "Enter Password",
                          "has-clear-btn": true,
                          "error-messages": _vm.passwordMessages,
                          type: "password",
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _c("mew-input", {
                        staticClass:
                          "flex-grow-1 CreateWalletKeystoreConfirmPWInput",
                        attrs: {
                          hint: "",
                          label: "Confirm Password",
                          placeholder: "Confirm password",
                          type: "password",
                          "error-messages": _vm.errorPasswordConfirmation,
                        },
                        model: {
                          value: _vm.cofirmPassword,
                          callback: function ($$v) {
                            _vm.cofirmPassword = $$v
                          },
                          expression: "cofirmPassword",
                        },
                      }),
                      !_vm.isGeneratingKeystore
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c("mew-button", {
                                staticClass: "mr-1",
                                attrs: {
                                  title: "Back",
                                  "btn-size": "xlarge",
                                  "btn-style": "outline",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.backToOverview.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _c("mew-button", {
                                staticClass: "CreateWalletKeystoreSubmitButton",
                                attrs: {
                                  title: "Create Wallet",
                                  "btn-size": "xlarge",
                                  "has-full-width": false,
                                  disabled: !_vm.enableCreateButton,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.createWallet.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            {
                              staticClass: "mt-1 mb-7",
                              attrs: { justify: "center", align: "center" },
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: "greenPrimary",
                                },
                              }),
                              _c("p", { staticClass: "mb-0 mx-3" }, [
                                _vm._v(
                                  "Sit tight while we are encrypting your wallet"
                                ),
                              ]),
                            ],
                            1
                          ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.step === 2
              ? {
                  key: "stepperContent2",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "subtitle-1 font-weight-bold grey--text step-two-header",
                        },
                        [_vm._v(" STEP 2. ")]
                      ),
                      _c("div", { staticClass: "headline font-weight-bold" }, [
                        _vm._v("Download keystore file"),
                      ]),
                      _c("div", { staticClass: "mb-5" }, [
                        _vm._v(
                          " Important things to know before downloading your keystore file. "
                        ),
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "align-stretch" },
                        _vm._l(_vm.warningData, function (d, key) {
                          return _c(
                            "v-col",
                            { key: key, attrs: { cols: "12", sm: "4" } },
                            [
                              _c(
                                "div",
                                { staticClass: "pa-6 border-container" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-center py-3",
                                    },
                                    [
                                      _c("mew-icon", {
                                        attrs: {
                                          "icon-name": d.icon,
                                          "img-height": 70,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "font-weight-bold mt-1 mb-2",
                                    },
                                    [_vm._v(_vm._s(d.title))]
                                  ),
                                  _c("div", [_vm._v(_vm._s(d.description))]),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column flex-md-row justify-center mt-6",
                        },
                        [
                          _c("mew-button", {
                            staticClass: "mx-md-1 my-1",
                            attrs: {
                              title: "Back",
                              "btn-style": "outline",
                              "btn-size": "xlarge",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.updateStep(1)
                              },
                            },
                          }),
                          _c("mew-button", {
                            staticClass:
                              "mx-md-1 my-1 CreateWalletKeystoreAccept",
                            attrs: {
                              title: "Acknowledge & Download",
                              "btn-size": "xlarge",
                              "has-full-width": false,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.downloadWallet.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("a", {
                        ref: "downloadLink",
                        staticClass: "link",
                        attrs: {
                          href: _vm.walletFile,
                          rel: "noopener noreferrer",
                          download: _vm.name,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.step === 3
              ? {
                  key: "stepperContent3",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "subtitle-1 font-weight-bold grey--text step-three-header",
                            },
                            [_vm._v(" STEP 3. ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "headline font-weight-bold mb-3" },
                            [_vm._v("You are done!")]
                          ),
                          _c("p", { staticClass: "mb-6" }, [
                            _vm._v(
                              " You are now ready to take advantage of all that Ethereum has to offer! Access with keystore file should only be used in an offline setting. "
                            ),
                          ]),
                          _c("img", {
                            staticClass:
                              "done-image d-block d-sm-none mx-auto mt-12 mb-12",
                            attrs: {
                              src: require("@/assets/images/icons/icon-keystore-mew.png"),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center flex-column",
                            },
                            [
                              _c("mew-button", {
                                staticClass: "mb-3 CreateWalletKeystoreAccess",
                                attrs: {
                                  title: "Access Wallet",
                                  "btn-size": "xlarge",
                                  "has-full-width": false,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.goToAccess.apply(null, arguments)
                                  },
                                },
                              }),
                              _c("mew-button", {
                                attrs: {
                                  title: "Create Another Wallet",
                                  "has-full-width": false,
                                  "btn-style": "transparent",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.restart.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("img", {
                          staticClass: "d-none d-sm-block ml-8 icon-keystore",
                          attrs: {
                            src: require("@/assets/images/icons/icon-keystore-mew.png"),
                          },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }