var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-3 pt-16" },
    [
      _c(
        "v-row",
        { staticClass: "ad-form-component" },
        [
          _c("img", {
            staticClass: "ad-img d-md-inline",
            attrs: {
              src: require("@/assets/images/backgrounds/wave.png"),
              width: "100%",
            },
          }),
          _c(
            "v-col",
            { staticClass: "ad-form-container pb-15", attrs: { col: "12" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center justify-md-end",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("img", {
                        staticClass: "ad-form-img",
                        attrs: {
                          src: require("@/assets/images/backgrounds/mew-spaceman.png"),
                          alt: "mew spaceman",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-column justify-center align-start",
                      attrs: { cols: "12", md: "6", lg: "4" },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.$vuetify.breakpoint.mdAndDown
                              ? "mew-subtitle"
                              : "mew-title",
                            "font-weight-regular text-center pb-5 talk-to-marketing-title title-anchor-ad-mew",
                          ],
                        },
                        [
                          _vm._v(" Let's talk about your "),
                          _c("br"),
                          _vm._v(" marketing goals "),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-center pb-5 talk-to-marketing-caption",
                          staticStyle: { width: "278px" },
                        },
                        [
                          _vm._v(
                            " Request the full catalog and pricing of all "
                          ),
                          _c("br"),
                          _vm._v(" advertising positions. "),
                        ]
                      ),
                      _c("mew-button", {
                        staticClass: "margin-0-auto",
                        attrs: {
                          "btn-style": "background",
                          "color-theme": "primary",
                          "btn-size": "xlarge",
                          title: "CONTACT ADVERTISING TEAM",
                          "btn-link": "mailto:@info@myetherwallet.com",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }