import { render, staticRenderFns } from "./TheBuyHardwareWalletLayout.vue?vue&type=template&id=b4dc4ce8&scoped=true"
import script from "./TheBuyHardwareWalletLayout.vue?vue&type=script&lang=js"
export * from "./TheBuyHardwareWalletLayout.vue?vue&type=script&lang=js"
import style0 from "./TheBuyHardwareWalletLayout.vue?vue&type=style&index=0&id=b4dc4ce8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4dc4ce8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4dc4ce8')) {
      api.createRecord('b4dc4ce8', component.options)
    } else {
      api.reload('b4dc4ce8', component.options)
    }
    module.hot.accept("./TheBuyHardwareWalletLayout.vue?vue&type=template&id=b4dc4ce8&scoped=true", function () {
      api.rerender('b4dc4ce8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layouts-default/TheBuyHardwareWalletLayout.vue"
export default component.exports