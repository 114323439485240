var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "the-wallet-header" },
    [
      _c(
        "v-row",
        { staticClass: "d-flex align-center py-2 pb-md-4 pb-lg-2 px-1" },
        [
          _c(
            "v-col",
            {
              class: [
                _vm.$vuetify.breakpoint.md ||
                _vm.$vuetify.breakpoint.lg ||
                _vm.$vuetify.breakpoint.xl
                  ? "set-fixed-height"
                  : "",
                "d-flex align-center justify-space-between",
              ],
              attrs: {
                cols: "12",
                md: _vm.hasAds ? "6" : "8",
                lg: _vm.hasAds ? "6" : "8",
                "no-gutters": "",
                dense: "",
              },
            },
            [
              !_vm.isOfflineApp &&
              (_vm.network.type.chainID === 1 || _vm.network.type.chainID === 5)
                ? _c("div", { staticClass: "eth-banner d-flex" }, [
                    _c("div", { staticClass: "mr-5" }, [
                      _c("div", { staticClass: "pa-2 d-flex align-center" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/dapps/icon-dapp-coinbase.svg"),
                            width: "30",
                            height: "30",
                          },
                        }),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column align-start" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold textDark--text",
                            staticStyle: { "font-size": "0.95rem" },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                "NEW: Stake any amount of ETH powered by Coinbase"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            class: [
                              _vm.$vuetify.breakpoint.md ||
                              _vm.$vuetify.breakpoint.lg ||
                              _vm.$vuetify.breakpoint.xl
                                ? ""
                                : "py-2",
                              "mew-body textMedium--text",
                            ],
                          },
                          [
                            _vm._v(
                              "Stake ETH with no minimums and start earning up to 4% APR right away; unstake at any time. "
                            ),
                            _vm.ads.length > 0 ? _c("br") : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "greenPrimary--text font-weight-bold cursor--pointer",
                                on: { click: _vm.stakeNow },
                              },
                              [_vm._v("Stake now")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.$vuetify.breakpoint.md ||
          _vm.$vuetify.breakpoint.lg ||
          _vm.$vuetify.breakpoint.xl
            ? _c(
                "v-col",
                {
                  staticClass: "ml-auto d-flex align-center justify-end",
                  attrs: { cols: _vm.ads.length >= 1 ? "6" : "4" },
                },
                [
                  _vm.hasAds
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        _vm._l(_vm.ads, function (ad, idx) {
                          return _c(
                            "v-menu",
                            {
                              key: ad.buttonIcon + "" + idx,
                              attrs: {
                                "open-on-hover": "",
                                "close-on-content-click": true,
                                "close-delay": "500",
                                "offset-y": "",
                                "nudge-top": "-10",
                                "nudge-left": "200",
                                "content-class": "img-holder",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "d-flex align-center justify-center white--text mr-3 cursor--pointer ad-button-template",
                                                style: ad.buttonGradient,
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  "https://img.mewapi.io/?image=" +
                                                  ad.buttonIcon,
                                                height: "40",
                                                width: "40",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { style: ad.titleColor },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(ad.buttonTitle) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: ad.popoverLink,
                                    target: "_blank",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.buttonTracking(ad.adName)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://img.mewapi.io/?image=" +
                                        ad.popoverImg,
                                      width: "300",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.ads.length < 3
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "ad-button-template prototype-background d-flex align-center justify-center white--text mr-3",
                          attrs: {
                            href: "https://www.myetherwallet.com/advertise-with-us",
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.buttonTracking("AdvertiseWithUs")
                            },
                          },
                        },
                        [_c("span", [_vm._v(" Advertise With Us ")])]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "align-center d-none d-lg-block" },
                    [
                      _vm.online
                        ? _c("notification-overlay", {
                            attrs: { "invert-icon": _vm.$vuetify.theme.dark },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }