var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _vm.addMode
        ? _c("mew-input", {
            attrs: {
              "show-blockie": true,
              label: _vm.$t("interface.address-book.address"),
              placeholder: _vm.$t("interface.address-book.enter-addr"),
              value: _vm.addressToAdd,
              rules: _vm.addressRules,
              "persistent-hint": _vm.validAddress,
              hint: _vm.resolvedName || _vm.nametag || _vm.coin,
              "resolved-addr": _vm.resolvedAddress,
              autofocus: "",
            },
            on: { input: _vm.setAddress },
          })
        : _vm._e(),
      _vm.editMode
        ? _c(
            "div",
            { staticClass: "full-width d-flex align-center mb-7" },
            [
              _c("mew-blockie", {
                staticClass: "mr-3",
                attrs: {
                  address: _vm.item.resolvedAddr
                    ? _vm.item.resolvedAddr
                    : _vm.item.address,
                  height: "45px",
                  width: "45px",
                },
              }),
              _c("div", { staticClass: "truncate" }, [
                _c("h5", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("interface.address-book.address")) + " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "monospace mr-3 truncate",
                        attrs: { id: "item-addr" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getXDCAddress(_vm.checksumAddressToAdd)
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("mew-copy", {
                      attrs: {
                        "copy-value": _vm.item.address,
                        tooltip: _vm.$t("common.copy"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("mew-input", {
        staticClass: "mt-2",
        attrs: {
          label: _vm.$t("interface.address-book.nickname"),
          placeholder: _vm.nickname
            ? _vm.nickname
            : _vm.$t("interface.address-book.enter-nickname"),
          value: _vm.nickname,
          rules: _vm.nicknameRules,
        },
        on: { input: _vm.setNickname },
      }),
      _c(
        "div",
        { staticClass: "text-center mt-4" },
        [
          _c("mew-button", {
            attrs: {
              disabled: _vm.disabled,
              title: _vm.editMode
                ? _vm.$t("interface.address-book.update")
                : _vm.$t("common.confirm-add"),
              "btn-size": "xlarge",
            },
            nativeOn: {
              click: function ($event) {
                _vm.editMode ? _vm.update() : _vm.add()
              },
            },
          }),
        ],
        1
      ),
      _vm.editMode
        ? _c(
            "div",
            { staticClass: "mt-4 text-center" },
            [
              _c("mew-button", {
                attrs: {
                  title: _vm.$t("interface.address-book.remove-addr"),
                  "has-full-width": false,
                  "btn-size": "small",
                  "btn-style": "transparent",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.remove.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }