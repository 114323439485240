var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-auto mb-3", staticStyle: { "max-width": "550px" } },
      [
        _c("div", { staticClass: "mew-heading-2 py-8 text-center" }, [
          _vm._v(" Select ETH amount to stake "),
        ]),
        _c(
          "border-block",
          { staticClass: "pt-9 pb-0 px-3 px-sm-5" },
          [
            _c("mew-select", {
              attrs: {
                label: "Staking amount",
                items: _vm.selectItems,
                "error-messages": _vm.errorMessages,
                "buy-more-str": _vm.errorMessages
                  ? _vm.network.type.canBuy
                    ? "Buy more."
                    : null
                  : null,
                "filter-placeholder": "Search for Amount",
                "is-custom": "",
                outlined: "",
              },
              on: {
                buyMore: function () {
                  _vm.openBuySell("StakedAmount")
                },
                input: _vm.setAmount,
              },
              model: {
                value: _vm.selectedItem,
                callback: function ($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem",
              },
            }),
            _c(
              "div",
              { staticClass: "mt-12" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "py-1 text-uppercase textLight--text font-weight-bold",
                        attrs: { cols: "6", md: "6" },
                      },
                      [_vm._v(" Current APR ")]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "py-1 text-right greenPrimary--text",
                        attrs: { cols: "6", md: "6" },
                      },
                      [_vm._v(" " + _vm._s(_vm.currentAprFormatted) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "py-1 text-uppercase textLight--text font-weight-bold d-flex align-center",
                        attrs: { cols: "6", md: "6" },
                      },
                      [
                        _c("div", { staticClass: "staking-fee" }, [
                          _vm._v("Staking Fee"),
                        ]),
                        _c("mew-tooltip", {
                          staticClass: "ml-1",
                          attrs: { text: _vm.toolTipFee, "max-width": "320px" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "py-1 text-right",
                        attrs: { cols: "6", md: "6" },
                      },
                      [_vm._v(" " + _vm._s(_vm.stakingFee) + " ETH ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider", { staticClass: "greyMedium my-6" }),
            _c(
              "div",
              [
                _c("div", { staticClass: "mew-heading-3 mb-8" }, [
                  _vm._v("Deposit value growth forecast"),
                ]),
                _vm._l(_vm.depositForecast, function (forecast, idx) {
                  return _c(
                    "div",
                    { key: forecast + idx, staticClass: "mb-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1",
                              attrs: { cols: "6", md: "6" },
                            },
                            [_vm._v(_vm._s(forecast.duration))]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1 text-right textLight--text",
                              attrs: { cols: "6", md: "6" },
                            },
                            [_vm._v(" " + _vm._s(forecast.balanceFiat) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1 greenPrimary--text",
                              attrs: { cols: "6", md: "6" },
                            },
                            [_vm._v(_vm._s(forecast.earningsETH + " ETH"))]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1 text-right",
                              attrs: { cols: "6", md: "6" },
                            },
                            [_vm._v(_vm._s(forecast.balanceETH + " ETH"))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._m(0),
        _c("mew-button", {
          staticClass: "mx-auto d-block mt-9",
          attrs: {
            loading: false,
            "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
            "btn-size": "xlarge",
            title: _vm.buttonText,
            disabled: !_vm.hasEnoughBalance,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.onContinue.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "bgWalletBlockDark px-6 px-sm-12 py-8 mt-6 border-radius--10px",
      },
      [
        _c("ul", { staticClass: "user-info textMedium--text" }, [
          _c("li", [_vm._v("Your ETH is staked with our partner Staked.us")]),
          _c("li", [
            _vm._v(
              "Staked.us will create and maintain Eth2 validators for you"
            ),
          ]),
          _c("li", [_vm._v("Earn up to 6% annualized rewards")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }