var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-10 py-md-15 text-center pt-16" },
    [
      _c("img", {
        staticStyle: { "max-width": "900px" },
        attrs: {
          src: require("@/assets/images/backgrounds/bg-404.svg"),
          alt: "404",
        },
      }),
      _c("div", { staticClass: "px-2" }, [
        _c(
          "div",
          {
            staticClass: "textDark--text mt-6",
            class: _vm.$vuetify.breakpoint.mdAndUp
              ? "mew-title"
              : "mew-subtitle",
          },
          [_vm._v(" Oops! Page not found. ")]
        ),
        _c(
          "div",
          {
            staticClass: "greenPrimary--text mx-auto mt-2 mt-md-6",
            class: _vm.$vuetify.breakpoint.mdAndUp
              ? "mew-heading-2"
              : "mew-heading-3",
            staticStyle: { "max-width": "400px" },
          },
          [_vm._v(" We can't seem to find the page you are looking for. ")]
        ),
      ]),
      _c("mew-button", {
        staticClass: "mt-8",
        attrs: { title: "Home", "btn-size": "xlarge" },
        nativeOn: {
          click: function ($event) {
            return _vm.$router.push({ name: "AccessWallet" })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }