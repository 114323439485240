var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-component--features" }, [
    _c(
      "div",
      { staticClass: "desktop-content d-none d-lg-block" },
      [
        _c("div", { staticClass: "py-10" }),
        _c(
          "v-sheet",
          {
            staticClass: "mx-auto text-center",
            attrs: { color: "transparent", "max-width": "400px" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "mew-heading-3 text-uppercase textMedium--text mb-1",
              },
              [_vm._v(" " + _vm._s(_vm.$t("home.features.subheading")) + " ")]
            ),
            _c("h1", { staticClass: "mb-9" }, [
              _vm._v(_vm._s(_vm.$t("home.features.heading"))),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "features-tabs-container" },
          [
            _c(
              "v-container",
              [
                _c("mew-tabs", {
                  staticClass:
                    "mew-tabs-container d-flex align-center flex-column",
                  attrs: { items: _vm.mewTabs, "is-block": "" },
                  on: { onTab: _vm.trackClick },
                  scopedSlots: _vm._u([
                    {
                      key: "tabContent1",
                      fn: function () {
                        return [
                          _c("home-features-tokens", {
                            staticClass: "mt-16 mb-10",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "tabContent2",
                      fn: function () {
                        return [
                          _c("home-features-send", {
                            staticClass: "mt-16 mb-10",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "tabContent3",
                      fn: function () {
                        return [
                          _c("home-features-swap", {
                            staticClass: "mt-16 mb-10",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "tabContent4",
                      fn: function () {
                        return [
                          _c("home-features-dapps", {
                            staticClass: "mt-16 mb-10",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mobile-content d-block d-lg-none" },
      [
        _c("div", { staticClass: "py-7" }),
        _c(
          "v-container",
          [
            _c(
              "v-sheet",
              {
                staticClass: "mx-auto",
                attrs: { color: "transparent", "max-width": "500px" },
              },
              [
                _c(
                  "h5",
                  {
                    staticClass:
                      "font-weight-bold text-uppercase textMedium--text mb-2",
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("home.features.subheading")) + " "
                    ),
                  ]
                ),
                _c("h1", { staticClass: "mb-10" }, [
                  _vm._v(_vm._s(_vm.$t("home.features.heading"))),
                ]),
                _c(
                  "v-tabs",
                  {
                    attrs: { "fixed-tabs": "" },
                    model: {
                      value: _vm.mobileTab,
                      callback: function ($$v) {
                        _vm.mobileTab = $$v
                      },
                      expression: "mobileTab",
                    },
                  },
                  _vm._l(_vm.mobileItems, function (item) {
                    return _c("v-tab", { key: item.tab }, [
                      _vm._v(" " + _vm._s(item.tab) + " "),
                    ])
                  }),
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.mobileTab,
                      callback: function ($$v) {
                        _vm.mobileTab = $$v
                      },
                      expression: "mobileTab",
                    },
                  },
                  _vm._l(_vm.mewTabs, function (item) {
                    return _c("v-tab-item", { key: item.tab }, [
                      _c(
                        "div",
                        { staticClass: "mt-10 mb-5" },
                        [
                          item.name === "ETH"
                            ? _c("home-features-send")
                            : _vm._e(),
                          item.name === "Swap"
                            ? _c("home-features-swap")
                            : _vm._e(),
                          item.name === "DApps"
                            ? _c("home-features-dapps")
                            : _vm._e(),
                          item.name === "Tokens"
                            ? _c("home-features-tokens")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "py-2" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }