var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module-balance" },
    [
      _vm.loadingWalletInfo ? _c("loader") : _vm._e(),
      _vm.hasBalance && !_vm.loadingWalletInfo
        ? _c("mew-module", {
            staticClass: "bgWalletBlock",
            attrs: {
              subtitle: _vm.subtitle,
              title: _vm.title,
              "has-body-padding": false,
              icon: _vm.network.type.icon,
              caption: _vm.convertedBalance,
              "has-elevation": true,
              "has-full-height": true,
              "icon-align": "left",
            },
            scopedSlots: _vm._u(
              [
                false
                  ? {
                      key: "rightHeaderContainer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center ml-8 mt-3 mt-sm-0",
                            },
                            [
                              _c("mew-toggle", {
                                attrs: {
                                  "button-group": _vm.chartButtons,
                                  "on-toggle-btn-idx": _vm.activeButton,
                                },
                                on: { onBtnClick: _vm.onToggle },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "moduleBody",
                  fn: function () {
                    return [
                      false
                        ? _c("balance-chart", {
                            staticClass: "full-width mt-5 pa-md-3",
                            attrs: { data: _vm.chartData },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-3 pa-sm-7 d-block d-md-flex align-center justify-space-between",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column flex-sm-row align-center justify-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.network.type.currencyName
                                          ) +
                                          " PRICE "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "ml-2 font-weight-regular",
                                        _vm.priceChange
                                          ? "greenPrimary--text"
                                          : "redPrimary--text",
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.formatChange) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      class: [
                                        _vm.priceChange
                                          ? "greenPrimary--text"
                                          : "redPrimary--text",
                                        "body-2",
                                      ],
                                    },
                                    [_vm._v(_vm._s(_vm.priceChangeArrow))]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ml-sm-5" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatFiatPrice) +
                                    " / 1 " +
                                    _vm._s(_vm.network.type.currencyName) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-center text-md-right mt-4 mt-md-0",
                            },
                            [
                              _c("mew-button", {
                                staticClass: "mr-3",
                                attrs: {
                                  "has-full-width": false,
                                  title: _vm.sendText,
                                  "btn-size": "large",
                                  "btn-style": "transparent",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.navigateToSend.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _vm.hasSwap
                                ? _c("mew-button", {
                                    attrs: {
                                      "has-full-width": false,
                                      title: _vm.swapText,
                                      "btn-size": "large",
                                      "btn-style": "outline",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.navigateToSwap.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      !_vm.hasBalance && !_vm.loadingWalletInfo
        ? _c("balance-empty-block", {
            attrs: {
              "network-type": _vm.network.type.currencyName,
              "is-eth": _vm.isEthNetwork,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }