var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("white-sheet", { attrs: { sideinfo: !_vm.mobile } }, [
    _c("div", { staticClass: "px-5 px-lg-7 py-5" }, [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _c("span", { staticClass: "mew-heading-2" }, [
            _vm._v(_vm._s(_vm.$t("common.swap"))),
          ]),
          _c("mew-button", {
            attrs: {
              "btn-style": "transparent",
              "button-size": "small",
              title: _vm.$t("common.more") + "...",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.toSwap.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]),
    !_vm.loading && !_vm.error && _vm.hasSwapRates
      ? _c(
          "div",
          { staticClass: "pa-3" },
          _vm._l(_vm.swapRates, function (data, key) {
            return _c(
              "div",
              { key: key },
              [
                data.rate
                  ? _c(
                      "v-sheet",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between border-radius--5px mt-1 py-3 px-4 cursor",
                        attrs: { color: "buttonGrayLight" },
                        on: {
                          click: function ($event) {
                            return _vm.goToSwap(data)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            " 1 " +
                              _vm._s(data.fromT.symbol) +
                              " / " +
                              _vm._s(data.rate) +
                              " " +
                              _vm._s(data.toT.symbol) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("mew-token-container", {
                              staticClass: "pa-1",
                              attrs: { size: "small", img: _vm.ethIcon },
                            }),
                            _c("img", {
                              staticClass: "mx-2",
                              attrs: {
                                width: "18",
                                src: require("@/assets/images/icons/icon-swap-arrow-grey.png"),
                                alt: "swap-icon",
                              },
                            }),
                            _c("mew-token-container", {
                              attrs: {
                                size: "small",
                                img: require("@/assets/images/currencies/" +
                                  data.toT.symbol.toLowerCase() +
                                  ".png"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "pa-3 pb-4 d-flex flex-column align-center justify-space-around",
          },
          [
            _c("v-progress-circular", { attrs: { indeterminate: "" } }),
            _c("h3", { staticClass: "ma-3" }, [
              _vm._v("Loading swap pairs..."),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showTokenIssue
      ? _c(
          "div",
          {
            staticClass:
              "pa-3 pb-4 d-flex flex-column align-center justify-space-around",
          },
          [
            _c("v-progress-circular", { attrs: { indeterminate: "" } }),
            _c("h3", { staticClass: "ma-3" }, [
              _vm._v("Having issues loading tokens."),
            ]),
            _c(
              "h5",
              {
                staticClass: "mb-2 cursor--pointer greenPrimary--text",
                on: { click: _vm.fetchRates },
              },
              [_vm._v(" Try again? ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }