var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "white-sheet",
    {
      staticClass: "mew-component--features-tokens pa-6 pa-md-10",
      attrs: { "max-width": "700px" },
    },
    [
      _c("div", { staticClass: "mew-heading-1 mb-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("home.features.tokens.heading")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "v-row",
            _vm._l(_vm.tokens, function (t, k) {
              return _c(
                "v-col",
                { key: k, attrs: { cols: "12", lg: "4", sm: "6" } },
                [
                  _c("a", { attrs: { href: t.link } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center no-link-colors" },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          attrs: { src: t.icon, alt: t.label, height: "20" },
                        }),
                        _c("div", [_vm._v(_vm._s(t.label))]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            1
          ),
          _c("mew-button", {
            staticClass: "mx-auto mt-12 d-block",
            attrs: { title: "Get tokens", "btn-size": "xlarge" },
            nativeOn: {
              click: function ($event) {
                return _vm.navigateToAccessWallet.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }