var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("white-sheet", { attrs: { sideinfo: !_vm.mobile } }, [
    _c("div", { staticClass: "px-5 px-lg-7 py-5" }, [
      _c("div", { staticClass: "mew-heading-2" }, [
        _vm._v("MEW exclusive holiday NFTs"),
      ]),
      _c(
        "a",
        {
          staticClass:
            "mew-heading-4 d-flex align-center py-2 more-mew-universe",
          attrs: {
            href: "https://www.myetherwallet.com/blog/join-us-in-the-mew-universe/",
            target: "_blank",
          },
          on: {
            click: function () {
              return _vm.trackNftModule("Mewtopia")
            },
          },
        },
        [
          _vm._v(" More about MEW universe "),
          _c("v-icon", { attrs: { color: "#5A678A" } }, [
            _vm._v(" mdi-chevron-right "),
          ]),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "single-mint-section d-flex align-center justify-space-around justify-md-space-between my-2 flex-wrap text-center",
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c(
                    "div",
                    { staticClass: "nft-image mb-2" },
                    [
                      _c("v-skeleton-loader", {
                        attrs: {
                          height: _vm.imageContainerWidth,
                          width: _vm.imageContainerWidth,
                          type: "image",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("mew-button", {
                    class: _vm.loading ? "" : "button-background",
                    attrs: {
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      "has-full-width": "",
                      "btn-size": "small",
                      "btn-style": _vm.loading ? "outline" : "transparent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c(
                    "div",
                    { staticClass: "nft-image mb-2" },
                    [
                      _c("v-skeleton-loader", {
                        attrs: {
                          height: _vm.imageContainerWidth,
                          width: _vm.imageContainerWidth,
                          type: "image",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("mew-button", {
                    class: _vm.loading ? "" : "button-background",
                    attrs: {
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      "has-full-width": "",
                      "btn-size": "small",
                      "btn-style": _vm.loading ? "outline" : "transparent",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "single-mint-section d-flex align-center justify-space-around justify-md-space-between my-2 flex-wrap text-center",
            },
            _vm._l(_vm.singlePaidItems, function (item) {
              return _c(
                "div",
                {
                  key: item.description + item.title,
                  staticClass: "image-container",
                },
                [
                  _c("img", {
                    staticClass: "mb-2 nft-image",
                    attrs: { src: item.url, alt: item.title },
                  }),
                  _c("mew-button", {
                    class: _vm.loading
                      ? ""
                      : _vm.darkMode
                      ? ""
                      : "button-background",
                    attrs: {
                      loading: _vm.loading || _vm.loaders[item.reward_id],
                      disabled: _vm.loading,
                      "has-full-width": "",
                      title: "Mint · " + item.pricef + " ETH",
                      "btn-size": "small",
                      "btn-style": _vm.loading
                        ? "outline"
                        : _vm.darkMode
                        ? "outline"
                        : "transparent",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.mint(item.reward_id)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
      _c(
        "div",
        [
          _c("mew-button", {
            attrs: {
              loading: _vm.loading || _vm.loaders[_vm.mintBothId],
              disabled: _vm.loading,
              title: _vm.mintBothText,
              "has-full-width": "",
              "btn-style": _vm.darkMode ? "light" : "primary",
              "btn-size": "large",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.mint(_vm.mintBothId)
              },
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass: "mt-2 textLight--text text-center mew-label",
            },
            [_vm._v(" Save on price and gas when you mint both ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }