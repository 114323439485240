var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "white-sheet",
    {
      staticClass:
        "mew-component--features-dapps px-6 pb-6 pt-5 pt-dm-4 pa-md-10",
      attrs: { "max-width": "700px" },
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.dapps, function (d, k) {
          return _c(
            "v-col",
            { key: k, attrs: { cols: "12", lg: "4", sm: "6" } },
            [
              _c(
                "div",
                {
                  class: [
                    d.isAccessible ? "align-center" : "align-start",
                    "d-flex",
                  ],
                },
                [
                  _c("img", {
                    staticClass: "mr-2",
                    class: { "icon-disabled": !d.isAccessible },
                    attrs: { src: d.icon, alt: d.label, height: "85" },
                  }),
                  _c(
                    "div",
                    [
                      !d.isAccessible
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "badge mew-label disabledPrimary--text d-block text--center mt-3 pb-5 pl-2",
                            },
                            [_vm._v(" Coming soon ")]
                          )
                        : _vm._e(),
                      !d.isAccessible ? _c("v-spacer") : _vm._e(),
                      _c(
                        "div",
                        {
                          class: [
                            d.isAccessible
                              ? "textDark--text "
                              : "textMedium--text",
                          ],
                        },
                        [_vm._v(" " + _vm._s(d.label) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }